import React, { useState, useEffect } from "react";
import "../Customer/Customer.css";
import "./view.css";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { BaseUrl } from "../../Api/api.js";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import Popup from "reactjs-popup";
import PageLoader from "../../components/PageLoader/PageLoader";

const Vendorviewprojectlist = () => {
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const userToken = localStorage.getItem("token");
  const ID = useParams().proposal_id;
  console.log(ID);

  useEffect(() => {
    setPageLoading(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${BaseUrl}/vendordashboard/Vendor-Proposal-List`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          console.log("result", result.data);
          setData(result.data);
          setPageLoading(false);
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);

  data.map((item) => {
    item.milestonelist.map((key) => {
      console.log("milestone details", key.milestone_description);
    });
  });

  return (
    <>
      {pageLoading && <PageLoader />}
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar />

          <main className="maincontent">
            <div>
              {data
                .filter((p_id) => p_id.proposal_id == ID)
                .map((item) => {
                  console.log(item);
                  return (
                    <>
                      <h3 className="VPDproposalhead">Project Proposal</h3>
                      <div className="VPDcard1">
                        <table>
                          <tr>
                            <td className=" ctitle">Project Name</td>
                            <td className=" cbody">
                              {parse(item.project_name)}
                            </td>
                          </tr>

                          <tr className="wrap">
                            <td className=" ctitle">Project Description</td>
                            <td className="cbody">
                              {parse(item.project_description)}
                            </td>
                          </tr>

                          <tr className="wrap">
                            <td className=" ctitle">Proposal Resources</td>
                            <td className=" cbody">
                              {parse(item.proposal_resources)}
                            </td>
                          </tr>

                          <tr className="wrap">
                            <td className=" ctitle">Proposal Support</td>
                            <td className=" cbody">
                              {parse(item.proposal_support)}
                            </td>
                          </tr>

                          <tr className="wrap">
                            <td className=" ctitle">Proposal Description</td>
                            <td className=" cbody">
                              {parse(item.perposal_description)}
                            </td>
                          </tr>

                          <tr className="wrap">
                            <td className="ctitle">Proposal Out of Scope</td>
                            <td className="cbody">
                              {parse(item.out_of_scope)}
                            </td>
                          </tr>

                          <tr className="wrap">
                            <td className=" ctitle">Proposal Price</td>
                            <td className=" cbody">
                              {item.price} {item.currency}
                            </td>
                          </tr>

                          <tr className="wrap">
                            <td className="ctitle">Proposal Timeline</td>
                            <td className="cbody">
                              {item.timeline} {item.timeline_unit}
                            </td>
                          </tr>

                          <tr className="wrap">
                            <td className="ctitle">
                              Proposal Terms & Conditions
                            </td>
                            <td className="cbody">
                              {parse(item.proposal_termsconditions)}
                            </td>
                          </tr>
                        </table>
                      </div>

                      <h3 className="VPDproposalhead2">Milestone</h3>
                      <div className="container">
                        <table className="rwd-table">
                          <tbody>
                            <tr class="tabletopback">
                              <th>S.No.</th>
                              <th>Description </th>
                              <th>Percent</th>
                              <th>Amount</th>
                              <th>Days</th>
                            </tr>

                            {item.milestonelist.map((key, index) => {
                              return (
                                <tr>
                                  <td
                                    className="milestone-data"
                                    data-th="S.No."
                                  >
                                    {index + 1}
                                  </td>
                                  <td
                                    className="milestone-data"
                                    data-th="Description "
                                  >
                                    {key.milestone_description}
                                  </td>
                                  <td
                                    className="milestone-data"
                                    data-th="Percent "
                                  >
                                    {key.milestone_percent}%
                                  </td>
                                  <td
                                    className="milestone-data"
                                    data-th="Amount "
                                  >
                                    {item.currency} {key.milestone_amount}
                                  </td>
                                  <td
                                    className="milestone-data"
                                    data-th="Days "
                                  >
                                    {key.milstone_timeline}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>

                      {item.proposal_status == 1 ? (
                        <>
                          {/* <button
                              className="btn selectproposal"
                              onClick={() => {
                                formValues.action_type = 1;
                                formValues.announcement_id =
                                  item.announcement_id;
                                formValues.proposal_id = item.proposal_id;
                                setOpenModal(true);
                                proposalAcceptedList();
                              }}
                            >
                              Start Project
                            </button> */}

                          <button
                            className="btn-selectproposal"
                            onClick={() => {
                              window.location = `/ViewVendorMilestone/${item.proposal_id}`;
                            }}
                          >
                            View Milestone
                          </button>
                        </>
                      ) : item.proposal_status == 0 ? (
                        <>
                          <Link to={`/ViewVendorMilestone/${item.proposal_id}`}>
                            <button className="btn-apply_milestone">
                              Start Project
                            </button>
                          </Link>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Vendorviewprojectlist;
