import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import "./nbcd.css";
import Lock from "../../../assets/images/lock-1.svg";
import Into from "../../../assets/images/into.svg";
import Reload from "../../../assets/images/reload.svg";
import Smlock from "../../../assets/images/sm-lock.svg";
import Tag from "../../../assets/images/tag.svg";
import SmallButton from "./SmallButton";
import ProjectDetail from "./ProjectDetail";
import Serv from "../../../assets/images/serv.svg";
import BudgetIcon from "../../../assets/images/budgetIcon.svg";
import Lan from "../../../assets/images/lan.svg";
import Agency from "../../../assets/images/agency.svg";
import Location from "../../../assets/images/location.svg";
import Indus from "../../../assets/images/indus.svg";
import Pdf from "../../../assets/images/pdf.svg";
import Tc from "../../../assets/images/Tc.svg";
import mesIcon from "../../../assets/images/mesIcon.svg";
import applock from "../../../assets/images/applock.svg";
import skelton from "../../../assets/images/skelton.svg";
import AppLock from "../../../assets/images/AppLock-1.svg";
import Ellipse from "../../../assets/images/Ellipse256.svg";
import BackArrow from "../../../assets/images/backArrow.svg";
import ContactDetail from "./ContactDetail";
import contIcon from "../../../assets/images/contIcon.svg";
import position from "../../../assets/images/position.svg";
import cellIcon from "../../../assets/images/cellIcon.svg";
import EmailIcon from "../../../assets/images/EmailIcon.svg";
import Ind from "../../../assets/images/ind.svg";
import dep from "../../../assets/images/dep.svg";
import company from "../../../assets/images/comapny.svg";
import loc from "../../../assets/images/loc.svg";
import Man from "../../../assets/images/man.svg";
import Web from "../../../assets/images/web.svg";
import Facebook from "../../../assets/images/facebook.svg";
import Linkedin from "../../../assets/images/linkedin.svg";
import Twitter from "../../../assets/images/twitter.svg";
import inst from "../../../assets/images/inst.svg";
import GoogleMap from "./GoogleMap";
import { useLocation, useNavigate, useParams } from "react-router";
import axios from "axios";
import { BaseUrl, LogoutUrl } from "../../../Api/api";
import { toast } from "react-toastify";
import lo from "../../../assets/images/lo.svg";

import SuccessModal from "../../../components/Modal/BCDS/SuccessModal";
import FailureModal from "../../../components/Modal/BCDS/FailModal";
import Proposal from "../Proposal";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import w from "../../../assets/images/w.svg";
import ShowText from "./ShowText";
import TermsAndConditions from "../../../components/Modal/BCDS/TermsAndConditions";
import customer from "../../../assets/images/reveal-cust.png";
import companyText from "../../../assets/images/reveal-company.png";
import ProjectRestricted from "../../../components/Modal/ProjectRestricted";

const NewBusyCustomerDetailsBeforePayment = () => {
  const { announcement_id } = useParams();
  console.log("id==>", announcement_id);

  const pathName = window.location.pathname;

  const parts = pathName.split("/");

  const restrictedIds = new Set([269, 276, 268, 278]); // Add more IDs as needed
  const currentId = parseInt(parts[parts.length - 1], 10); // Convert to a number
  const isRestricted = restrictedIds.has(currentId);

  console.log(isRestricted, "isRest restricted");

  console.log("currentId==>", typeof currentId);

  const [filterData, setFilterData] = useState([]);
  const [popup, setPopup] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [projectApplyUrl, setProjectApplyUrl] = useState("");
  const [showTerms, setShowTerms] = useState(false);
  const [size, setSize] = useState();
  const [opportunityPrice, setOpportunityPrice] = useState({});
  const [showToastMessage, setShowToastMessage] = useState(false);

  const token = localStorage.getItem("token");
  let vendorInfo = JSON.parse(localStorage.getItem("userinfo"));
  const getData = localStorage.getItem("results");
  const data = JSON.parse(getData);

  const navigate = useNavigate();

  const toastRestrict = () => {
    setShowToastMessage(true);
  };

  const handleTermClose = () => {
    setShowTerms(false);
  };
  const handleTermOpen = () => {
    // navigate("/new-terms-and-conditions")
  };
  // console.log("new data==>", data);

  const onHide = () => {
    setShow(false);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleBack = () => {
    // navigate("/vendor-project-list");
    navigate("/customer-contact");
  };

  useEffect(() => {
    if (data && announcement_id) {
      const filterData = data.filter((item) => {
        return item.announcement_id == announcement_id;
      });
      setFilterData(filterData);
      window.scrollTo(0, 0);
    }
  }, [announcement_id]);

  useEffect(() => {
    axios
      .get(
        `${BaseUrl}/vendordashboard/Get-Customer-By-Project-New?announcement_id=${announcement_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          setCustomerData(res.data.data ? res.data.data : "");
          setOpportunityPrice(res.data.data.opportunityprice_details);
        } else {
          setOpportunityPrice(res.data.data.opportunityprice_details);
        }
      })
      .catch((err) => {});
  }, [announcement_id]);

  const announcementid = (announcement_id, project_name) => {
    localStorage.setItem("vendorproject", announcement_id);

    if (
      (vendorInfo.activation_status == 0 ||
        vendorInfo.activation_status == 1) &&
      vendorInfo.available_point < 100
    ) {
      toast.error("To Apply Project, You need to buy coins.");
      window.location = "/subscription";
      setOpenModal(false);
    } else {
      setProjectApplyUrl(
        `vendor-project-list/${announcement_id}/${project_name}`
      );
      setPopup(true);
    }
  };

  const srtipeAPI = async () => {
    const data = {
      project_id: announcement_id,
      currency: "USD",
      amount: "10",
      transactions_id: "123",
    };

    const token = localStorage.getItem("token");
    axios
      .post(
        `${BaseUrl}/vendordashboard/Payment-for-Customer-Details-New1`,
        data,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        window.location = res.data.data.url;
      })
      .catch((err) => {});
  };

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const paymentStatus = query.get("transaction_status");
  useEffect(() => {
    if (paymentStatus == "success") {
      setShow(true);
    } else if (paymentStatus == "failed") {
      setShowModal(true);
    }
  }, [paymentStatus]);

  // console.log("new filter data==>", filterData);

  const customerDetails = customerData.customerDetail;
  // console.log("userDetails==>", customerDetails);

  const companyDetails = customerData.companyDetail;
  // const companyDetails = {};
  // console.log("companyDetails==>", companyDetails);

  const first_name = customerDetails ? customerDetails.first_name : "";
  const first_word = first_name ? first_name.charAt(0) : "";
  // console.log("first word==>", first_word);

  const last_name = customerDetails ? customerDetails.last_name : "";
  const last_word = last_name ? last_name.charAt(0) : "";
  // console.log("last word==>", last_word);

  const name = first_word + last_word;
  // console.log("name==>", name);

  const date = filterData[0] ? filterData[0].created_at : "";
  // console.log("date==>", date);
  const D = date.split(" ")[0];
  // console.log("D==>", D);
  const newDate = companyDetails ? companyDetails.created_at : "";
  const Dat = new Date(newDate);
  const formattedDate = `${Dat.getDate()}/${
    Dat.getMonth() + 1
  }/${Dat.getFullYear()}`;
  // console.log("newDate==>", formattedDate);

  const facebook = companyDetails && companyDetails.facebook_url;
  const twitter = companyDetails && companyDetails.twitter_url;
  const linkedin = companyDetails && companyDetails.linkedin_url;
  const instagram = companyDetails && companyDetails.instagram_url;
  // console.log("facebook==>", facebook);
  // console.log("twitter==>", twitter);
  // console.log("linkedin==>", linkedin);
  // console.log("instagram==>", instagram);
  const handleFacebook = () => {
    window.open(`https://${facebook}`, "_blank");
  };
  const handleTwitter = () => {
    window.open(`https://${twitter}`, "_blank");
  };
  const handleLinkedin = () => {
    window.open(`https://${linkedin}`, "_blank");
  };
  const handleInstagram = () => {
    window.open(`https://${instagram}`, "_blank");
  };

  const handleTermsAndConditions = () => {
    // window.open("https://dev2.conqt.com/terms-and-conditions")
    window.open(`${LogoutUrl}/terms-and-conditions`);
  };

  const whatsappNumber = "913 686 0189";
  const whatsappMessage = `Hi`;
  const watsapp = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(
    whatsappMessage
  )}`;
  const handleWatsapp = () => {
    window.open(watsapp);
  };
  return (
    <>
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar isShow={false} />
          {openModal && <Proposal closeModal={setOpenModal} />}
          {
            <SuccessModal
              open={show}
              handleClose={onHide}
              announcement_id={announcement_id}
            />
          }
          {
            <FailureModal
              open={showModal}
              handleClose={handleClose}
              announcement_id={announcement_id}
            />
          }
          {/* {<TermsAndConditions open={showTerms} handleClose={handleTermClose} />} */}

          {showToastMessage && (
            <ProjectRestricted
              showPopup={showToastMessage}
              setShowPopup={setShowToastMessage}
            />
          )}
          <div className="mt-3 mb-5">
            <div className="container py-3">
              <div className="container top-unlock-component py-3">
                <div className="container">
                  <FaArrowLeft
                    style={{ width: "18px", cursor: "pointer" }}
                    onClick={handleBack}
                  />
                </div>

                <div className="container py-2 ">
                  {filterData[0] &&
                  filterData[0].vendor_id == vendorInfo.vendor_id ? (
                    <span class="badge bg-success">Applied</span>
                  ) : (
                    ""
                  )}
                </div>

                <div className=" container">
                  <div className="row justify-content-between">
                    {filterData.map((item) => {
                      return (
                        <div className="col-12 col-lg-7">
                          <h3 className="unlock-top-head">
                            {item.project_name}
                          </h3>
                          <p>{D}</p>
                          <h4 className="unlock-para pt-2">
                            <ShowText
                              text={item.project_description}
                              maxLength={220}
                            />
                          </h4>
                        </div>
                      );
                    })}
                    <div
                      className="col-12 col-lg-4 lock-div pt-2 pb-3"
                      style={{ height: "265px", width: "280px" }}
                    >
                      {(companyDetails && companyDetails.created_at) ||
                      (customerDetails &&
                        Object.keys(customerDetails).length != 0) ? (
                        <div className="d-flex flex-column align-items-center justify-content-center ">
                          <div className="pt-5">
                            <button className="apply-pro-btn">Purchased</button>
                          </div>

                          <div
                            className="d-flex align-items-center pt-3"
                            style={{ width: "80%" }}
                          >
                            <div>
                              <img src={Tag} alt="" className="px-2" />
                            </div>
                            <div>Opportunity price: $10</div>
                          </div>
                          {/* <p
                            className="py-1"
                            style={{ margin: "0px", fontSize: "12px" }}
                          >
                            Excluding taxes
                          </p> */}
                          <div
                            className="d-flex align-items-center py-1"
                            style={{ width: "80%" }}
                          >
                            <div>
                              <img src={Reload} alt="" className="px-2" />
                            </div>
                            <div>60-day Refund Policy</div>
                          </div>

                          <div
                            className="d-flex align-items-center"
                            style={{ width: "80%", cursor: "pointer" }}
                            onClick={handleTermOpen}
                          >
                            <div>
                              <img src={Tc} alt="" className="px-2" />
                            </div>
                            <div
                              style={{
                                color: "#3EB489",
                                textDecoration: "underline",
                              }}
                              onClick={handleTermsAndConditions}
                            >
                              Terms and conditions apply
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex flex-column align-items-center justify-content-center ">
                          <div className="pt-2 pb-3">
                            <img src={Lock} alt="" style={{ width: "60px" }} />
                          </div>
                          <div
                            className="nbcd-unlock-btn"
                            onClick={isRestricted ? toastRestrict : srtipeAPI}
                          >
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{ width: "100%" }}
                            >
                              <SmallButton
                                style={{ width: "100%" }}
                                color="#fff"
                                backgroundColor="#304FFE"
                                buttonName={"Unlock"}
                                image={lo}
                              />
                            </div>
                          </div>

                          <div
                            className="d-flex align-items-center pt-3"
                            style={{ width: "80%" }}
                          >
                            <div>
                              <img
                                src={Tag}
                                alt=""
                                className="px-2"
                                style={{ width: "36px" }}
                              />
                            </div>
                            <div style={{ whiteSpace: "nowrap" }}>
                              Opportunity price:
                              {opportunityPrice?.price != 10 && (
                                <p
                                  style={{
                                    textDecoration: "line-through",
                                    color: "red",
                                    display: "inline",
                                    paddingLeft: "5px",
                                  }}
                                >
                                  $10
                                </p>
                              )}
                              <span>
                                <strong
                                  style={{
                                    fontSize: "17px",
                                    color: "#3EB489",
                                    fontWeight: "600",
                                    paddingLeft: "5px",
                                  }}
                                >
                                  ${opportunityPrice && opportunityPrice.price}
                                </strong>
                              </span>
                            </div>
                          </div>
                          <p style={{ margin: "0px", fontSize: "12px" }}>
                            (including taxes)
                          </p>
                          <div
                            className="d-flex align-items-center py-1"
                            style={{ width: "80%" }}
                          >
                            <div>
                              <img src={Reload} alt="" className="px-2" />
                            </div>
                            <div>60-day Refund Policy</div>
                          </div>

                          <div
                            className="d-flex align-items-center"
                            style={{ width: "80%", cursor: "pointer" }}
                            onClick={handleTermOpen}
                          >
                            <div>
                              <img src={Tc} alt="" className="px-2" />
                            </div>
                            <div
                              style={{
                                color: "#3EB489",
                                textDecoration: "underline",
                              }}
                              onClick={handleTermsAndConditions}
                            >
                              Terms and conditions apply
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="container pt-3">
                  <div className="d-flex align-items-center abt-project-name">
                    About the Project
                    {/* <div><img src={Pdf} alt="" style={{ width: "35px", paddingLeft: "15px" }} /></div> */}
                  </div>
                  <div className="row">
                    {filterData.map((item) => {
                      return (
                        <>
                          <div className="col-12 col-lg-6 py-2">
                            <ProjectDetail
                              image={Serv}
                              title={"Service"}
                              subTitle={item.solution_name}
                            />
                          </div>

                          <div className="col-12 col-lg-6 py-2">
                            <ProjectDetail
                              image={BudgetIcon}
                              title={"Budget"}
                              subTitle={item.project_budget}
                            />
                          </div>

                          <div className="col-12 col-lg-6 py-2">
                            <ProjectDetail
                              image={Location}
                              title={"Location"}
                              subTitle={item.country}
                            />
                          </div>

                          <div className="col-12 col-lg-6 py-2">
                            <ProjectDetail
                              image={Indus}
                              title={"Industry"}
                              subTitle={"."}
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>

                {/* <div className='container d-flex p-3 my-4' style={{ background: "#F8F8F8" }}>
                                                                        <div>
                                                                                <img src={mesIcon} alt="" style={{ width: "40px" }} />
                                                                        </div>
                                                                        <div className='px-2'>
                                                                                <h4 className='b-unlock-sub-header'>Client Message :</h4>
                                                                                <img src={applock} alt="" style={{ width: "250px" }} className="py-2" />
                                                                                <img src={skelton} alt="" />
                                                                        </div>

                                                                </div> */}

                {/* <div className='container d-flex p-3 my-4' style={{ background: "#F8F8F8" }}>
                                                                        <div>
                                                                                <img src={mesIcon} alt="" style={{ width: "40px" }} />
                                                                        </div>
                                                                        <div className='px-2'>
                                                                                <h4 className='b-unlock-sub-header'>Client Message</h4>
                                                                                <div>
                                                                                        <h6>Hello world</h6>
                                                                                </div>
                                                                        </div>

                                                                </div> */}
              </div>
            </div>

            {Object.keys(customerData).length == 0 ? (
              <>
                {/* Before unlock customer Details */}
                <div className="container my-2">
                  <div className="dd p-4">
                    <h4 className="b-unlock-sub-header">Customer Details</h4>
                    <img
                      src={customer}
                      alt=""
                      style={{ width: "300px" }}
                      className="py-3"
                    />
                    <div
                      className="d-flex align-items-center justify-content-between pt-3"
                      style={{ width: "100%" }}
                    >
                      <div className="px-2">
                        <img src={Ellipse} alt="" />
                      </div>
                      <div>
                        <img src={skelton} alt="" />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Before unlock company Details */}
                <div className="container my-2">
                  <div className="dd p-4">
                    <h4 className="b-unlock-sub-header">Company Details</h4>
                    <img
                      src={companyText}
                      alt=""
                      style={{ width: "300px" }}
                      className="py-3"
                    />
                    <div
                      className="d-flex align-items-center justify-content-between pt-3"
                      style={{ width: "100%" }}
                    >
                      <div className="px-2">
                        <img src={Ellipse} alt="" />
                      </div>
                      <div>
                        <img src={skelton} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* After Unlock Customer details  */}
                <div className="container my-2">
                  <div className="dd p-4">
                    <div className="row align-items-center justify-content-between">
                      <div className="col-12 col-lg-5">
                        <h4 className="b-unlock-sub-header">
                          Customer Details
                        </h4>
                        <div className="d-flex py-3">
                          <div className="box-sha">
                            <h3>{name}</h3>
                          </div>
                          <div className="px-3">
                            <h5>{customerDetails.company_name}</h5>
                            <h6>
                              {" "}
                              <i>{customerDetails.country}</i>
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-4">
                        <ContactDetail
                          image={contIcon}
                          title={"Contact Name"}
                          subTitle={customerDetails.name}
                        />

                        <div className="py-2">
                          <ContactDetail
                            image={cellIcon}
                            title={"Phone Number"}
                            subTitle={customerDetails.phone_number}
                          />
                        </div>

                        <ContactDetail
                          image={position}
                          title={"Position"}
                          subTitle={customerDetails.position}
                        />
                      </div>
                      <div className="col-12 col-lg-3">
                        <ContactDetail
                          image={EmailIcon}
                          title={"Email Address"}
                          subTitle={customerDetails.contact_person_email}
                        />

                        <div className="py-2">
                          <ContactDetail
                            image={dep}
                            title={"Department"}
                            subTitle={customerDetails.department_name}
                          />
                        </div>

                        <ContactDetail
                          image={Ind}
                          title={"Industry"}
                          subTitle={customerDetails.customer_industry}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* After Unlock Company Details */}

                {Object.keys(companyDetails).length != 0 && (
                  <div className="container my-2">
                    <div className="dd p-4">
                      <div className="row">
                        <div className="col-12 col-lg-5">
                          <h5 className="b-unlock-sub-header">
                            Company Details
                          </h5>
                          <p className="con-detail-sub">
                            {" "}
                            <strong>Contact details</strong> of company
                          </p>
                          <div className="d-flex align-items-center">
                            <button
                              className="con-to-btn mt-3"
                              onClick={handleWatsapp}
                            >
                              <h6
                                className="con-to-btn-name"
                                style={{ cursor: "pointer" }}
                              >
                                <span className="px-2">
                                  <img
                                    src={w}
                                    alt=""
                                    style={{ width: "20px" }}
                                  />
                                </span>
                                Request for Support
                              </h6>
                            </button>
                            {/* <img src={watsap} alt="" className='pt-3' style={{ width: "43px", cursor: "pointer" }}
                                                                                                                        onClick={handleWatsapp}
                                                                                                                /> */}
                          </div>
                        </div>
                        <div className="col-12 col-lg-7">
                          <h5 className="cont-detail-des-head">
                            Company Description{" "}
                          </h5>
                          <p className="cont-detail-des pt-2">
                            {/* {companyDetails.about_agency} */}
                            <ShowText
                              text={
                                companyDetails.about_agency &&
                                companyDetails.about_agency
                              }
                              maxLength={200}
                            />
                          </p>
                        </div>
                      </div>

                      <h4 className="a-unlock-details pt-4">Details</h4>

                      <div className="row mt-4">
                        <div className="col-12  col-lg-5">
                          <div className="d-flex  flex-column justify-content-center">
                            <div className="">
                              <ContactDetail
                                image={company}
                                title={"Company type"}
                                subTitle={companyDetails.agency_type}
                              />
                            </div>

                            <div className="py-3">
                              <ContactDetail
                                image={EmailIcon}
                                title={"Company Email"}
                                subTitle={companyDetails.agency_email}
                              />
                            </div>

                            <div className="">
                              <ContactDetail
                                image={Web}
                                title={"Company Website"}
                                subTitle={companyDetails.agency_website}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-5">
                          {/* <div className="d-flex flex-column">
                                                                                                                <h4 className='a-unlock-details'>Details</h4>
                                                                                                                <div className="d-flex  flex-column justify-content-center">
                                                                                                                        <div className='py-3'>
                                                                                                                                <ContactDetail
                                                                                                                                        image={company}
                                                                                                                                        title={"Company type"}
                                                                                                                                        subTitle={companyDetails.agency_type}
                                                                                                                                />
                                                                                                                        </div>

                                                                                                                        <ContactDetail
                                                                                                                                image={EmailIcon}
                                                                                                                                title={"Company Email"}
                                                                                                                                subTitle={companyDetails.agency_email}
                                                                                                                        />

                                                                                                                        <div className='py-3'>
                                                                                                                                <ContactDetail
                                                                                                                                        image={Web}
                                                                                                                                        title={"Company Website"}
                                                                                                                                        subTitle={companyDetails.agency_website}
                                                                                                                                />
                                                                                                                        </div>

                                                                                                                        <ContactDetail
                                                                                                                                image={Man}
                                                                                                                                title={"Number of Employee"}
                                                                                                                                subTitle={companyDetails.no_of_employee}
                                                                                                                        />

                                                                                                                        <div className="py-3">
                                                                                                                                <ContactDetail
                                                                                                                                        image={loc}
                                                                                                                                        title={"Company Headquarter"}
                                                                                                                                        subTitle={companyDetails.agency_address}
                                                                                                                                />
                                                                                                                        </div>
                                                                                                                        <div className='social-icons'>
                                                                                                                                <img src={Facebook} alt="" style={{ width: "25px" }} onClick={handleFacebook} />
                                                                                                                                <img src={Linkedin} alt="" style={{ width: "25px" }} onClick={handleLinkedin} />
                                                                                                                                <img src={Twitter} alt="" style={{ width: "25px" }} onClick={handleTwitter} />
                                                                                                                                <img src={inst} alt="" style={{ width: "25px" }} onClick={handleInstagram} />
                                                                                                                        </div>
                                                                                                                </div>
                                                                                                        </div> */}
                          <div className="d-flex flex-column">
                            <ContactDetail
                              image={Man}
                              title={"Number of Employee"}
                              subTitle={companyDetails.no_of_employee}
                            />

                            <div className="py-3">
                              <ContactDetail
                                image={loc}
                                title={"Company Headquarter"}
                                subTitle={companyDetails.agency_address}
                              />
                            </div>
                            <div className="social-icons">
                              <img
                                src={Facebook}
                                alt=""
                                style={{ width: "25px" }}
                                onClick={handleFacebook}
                              />
                              <img
                                src={Linkedin}
                                alt=""
                                style={{ width: "25px" }}
                                onClick={handleLinkedin}
                              />
                              <img
                                src={Twitter}
                                alt=""
                                style={{ width: "25px" }}
                                onClick={handleTwitter}
                              />
                              <img
                                src={inst}
                                alt=""
                                style={{ width: "25px" }}
                                onClick={handleInstagram}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className=" container d-flex align-items-center justify-content-end pb-5">
            <div>
              {filterData[0] &&
              filterData[0].vendor_id == vendorInfo.vendor_id ? (
                <>
                  <button
                    className="apply-btn"
                    style={{ backgroundColor: "gray" }}
                  >
                    Already Applied
                  </button>
                </>
              ) : (
                <>
                  <Link
                    to={`/vendor-project-list/${
                      filterData[0] && filterData[0].announcement_id
                    }/${filterData[0] && filterData[0].project_name}`}
                  >
                    {companyDetails &&
                    customerDetails &&
                    Object.keys(customerDetails).length != 0 ? (
                      <button
                        className="apply-btn"
                        onClick={() => {
                          setOpenModal(true);
                          announcementid(
                            filterData[0].announcement_id,
                            filterData[0].project_name
                          );
                        }}
                      >
                        Apply for project
                      </button>
                    ) : (
                      ""
                    )}
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewBusyCustomerDetailsBeforePayment;
