import React, { useState, useEffect } from "react";
import "./Customer.css";
import Sidebar from "./Sidebar";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import Topbar from "./Topbar";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-toastify";
import { BaseUrl } from "../../Api/api.js";
import {
  clearFormValues,
  updateFormValue,
} from "../../Store/Reducers/AddProjectsReducer";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import { resetWarned } from "react-bootstrap-typeahead/types/utils/warn";
import { useForm } from "react-hook-form";
import { format } from "date-fns/fp";

const AddProject = () => {
  const project = useSelector((state) => state.addProjectsReducer.formValues);
  const [isDirty, setIsDirty] = useState(false);
  const {
    register,
    // handleSubmit,
    getValues,
    setValue,
    reset,
    setError,
    formState: { errors },
  } = useForm();

  const user = useSelector((state) => JSON.parse(localStorage.getItem("user")));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [files, setFiles] = useState([]);

  const removeFile = (filename) => {
    setFiles(files.filter((file) => file.name !== filename));
  };

  useEffect(() => {
    reset();
  }, []);

  const initialFormData = {
    project_name: project?.project_name,
    project_description: project?.project_description,
    solution_name: project?.solution_name,
    country: project?.country ? project.country : user.user_info.country,
    city: project?.city ? project.city : user.user_info.city,
    project_expiry_datetime: project
      ? project.project_expiry_datetime
      : startDate,
    project_budget: project?.project_budget
      ? project.project_budget
      : "Budget Not Set",
  };

  const [isModal, setIsModal] = useState(false);
  const [formValues, setFormValues] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [solutions, setSolutions] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileError, setSelectedFileError] = useState("");
  const userToken = localStorage.getItem("token");

  // formValues.city = user.user_info.city;
  // formValues.country = user.user_info.country;

  // console.log(formValues.project_description?.length, "form values :");
  const handleFileInput = (e) => {
    const file = e.target.files[0];

    // check if the file is selected
    if (file) {
      const fileType = file.type;
      // check if the file type is not PDF
      if (fileType !== "application/pdf") {
        toast.warning(`File type must be PDF format`);
        setSelectedFileError("File must be in PDF format.");
        e.target.value = "";
        setSelectedFile(null);
      } else {
        setSelectedFileError("");
        setSelectedFile(e.target.files[0]);
        console.log(e.target.files[0]);
      }
    } else {
      setSelectedFileError("");
      setSelectedFile(null);
    }
  };

  const handleDateChange = (date, e) => {
    setStartDate(date);
    var format_date = String(moment(date).format("YYYY-MM-DD HH:mm:ss"));
    formValues.project_expiry_datetime = format_date;
    dispatch(updateFormValue({ project_expiry_datetime: format_date }));
  };

  const handleChangeText = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      ["project_description"]: e,
    }));
    setIsDirty(true);
    dispatch(updateFormValue({ project_description: e }));
  };

  const handleChange = (e) => {
    if (e["target"] !== undefined) {
      setFormValues((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
      const { name, value } = e.target;
      setIsDirty(true);
      dispatch(updateFormValue({ [name]: value }));
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        ["project_description"]: e.blocks[0].text,
      }));

      dispatch(updateFormValue({ project_description: e.blocks[0].text }));
    }
  };

  const [countryList, setCountryList] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [city, setCity] = useState();
  const [state, setState] = useState();

  // Countries options for countries to pass this data into the Selector components.
  const countries = countryList?.map((e) => ({
    value: e.country,
    label: e.country,
  }));
  // State options for all the states to pass this data into the Selector components.
  const statesOptions = states?.map((state) => ({
    value: state,
    label: state,
  }));
  // Cities options for all the cities to pass this data into the Selector components.
  const citiesOptions = cities?.map((city) => ({
    value: city,
    label: city,
  }));

  useEffect(() => {
    axios
      .get(`${BaseUrl}/common/Get-All-Country`)
      .then((response) => {
        if (response.data.status === 2000) {
          setCountryList(response.data.data.country_list);
        } else if (response.data.status === 401) {
          console.log("Unauthorized");
        }
      })
      .catch((error) => {
        console.log("state catch error", error);
      });
  }, []);

  // calling the API for states based on the selectedCountry
  useEffect(() => {
    if (selectedCountry) {
      axios
        .get(`${BaseUrl}/universal/getAllStates/${selectedCountry?.value}`)
        .then((res) => {
          if (res.data.status === 200) {
            setStates(res.data.data);
          } else if (res.data.status === 401) {
            console.log("state error");
          }
        })
        .catch((err) => {
          console.log("state catch error", err);
        });
    }
  }, [selectedCountry]);
  // calling the API for cities based on the selectedCountry and selectedState as well
  useEffect(() => {
    if (selectedCountry && selectedState) {
      axios
        .get(
          `${BaseUrl}/universal//getAllCities/${selectedCountry?.value}/${selectedState?.value}`
        )
        .then((res) => {
          if (res.data.status === 200) {
            setCities(res.data.data);
          } else if (res.data.status === 401) {
            console.log("city error");
          }
        })
        .catch((err) => {
          console.log("city catch error", err);
        });
    }
  }, [selectedCountry && selectedState]);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setSelectedState(null);
    setSelectedCity(null);
  };
  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
    setSelectedCity(null);
  };

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
  };

  const country = selectedCountry && selectedCountry?.value;
  // const city = selectedCity && selectedCity?.value

  const handleSubmit = async (e, data) => {
    console.log(setSelectedFile, "select file");
    e.preventDefault();
    let error = validate(formValues);
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);

      var formdata = new FormData();

      formdata.append("project_name", formValues.project_name);
      formdata.append("project_description", formValues.project_description);
      formdata.append("city", city);
      formdata.append("country", country);
      formdata.append("solution_name", formValues.solution_name);
      formdata.append("project_budget", formValues.project_budget);
      formdata.append(
        "project_expiry_datetime",
        formValues.project_expiry_datetime
      );
      if (selectedFile) {
        formdata.append("attachment", selectedFile);
      }

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      if (Object.keys(error).length === 0) {
        fetch(`${BaseUrl}/announcement/Create-Announcement`, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            result = JSON.parse(result);
            if (result.status === 200) {
              setIsDirty(false);
              setFormValues("");
              dispatch(clearFormValues());

              toast("Product Publish Successfully");
              // navigate("/projectlist")
            } else {
              // toast(result.message);
              if (selectedCity === null || selectedCity === undefined) {
                toast.error("Please select a city");
              }
              if (selectedState === null || selectedState === undefined) {
                toast.error("Please select a state");
              }
            }
          })
          .catch((error) => console.log("error", error));
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (selectedState) {
      setState(selectedState?.value);
    } else if (selectedState === null || selectedState === undefined) {
      setState("");
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedCity) {
      setCity(selectedCity?.value);
    } else if (selectedCity === null || selectedCity === undefined) {
      setCity("");
    }
  }, [selectedCity]);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0) {
    }

    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${BaseUrl}/vendor/Get-Solutions`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        //console.log("Get Solutions", JSON.parse(result).data);
        setSolutions(JSON.parse(result).data);
      })
      .catch((error) => console.log("error", error));
  }, [formErrors]);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors]);

  const validate = (values) => {
    console.log("validate", values);
    const errors = {};

    Object.entries(values).forEach(([key, value]) => {
      if (!String(values[key]).trim()) {
        const keyname = key;

        const Keyname = keyname.charAt(0).toUpperCase() + keyname.slice(1);

        errors[key] = `${Keyname} Field is required`;
      }
    });
    const logintype = localStorage.getItem("logintype");
    if (logintype == 2) {
      errors["project_name"] =
        "You cannot create the Announcement Due to Login type is Different";
    }
    console.log(errors);
    return errors;
  };

  const editorConfig = {
    placeholder: "Enter the Project Description Here....",
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";
        // setIsModal(true);
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirty]);

  return (
    <>
      <div className="dashboard-container">
        <Sidebar isDirty={isDirty} />
        <div className="main-dashboard-container">
          <Topbar isDirty={isDirty} />
          <main className="maincontent">
            <div className="addproborder">
              <h3 className="addproject">Create Project</h3>
            </div>

            <form
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
              className="Project-form"
            >
              <div className="fieldsgroup">
                <div className="formfieldsinput">
                  <div className="form-group">
                    <label>Project / Service Name</label>
                    <input
                      className="customer-project-input"
                      type="text"
                      name="project_name"
                      value={formValues?.project_name}
                      placeholder="Project Name"
                      onChange={handleChange}
                    />
                  </div>
                  <p className="err-message">{formErrors.project_name}</p>
                </div>
              </div>

              <div className="form-group">
                <label>Project / Services Description</label>

                <CKEditor
                  config={editorConfig}
                  type="texteditor textarea"
                  name="project_description"
                  placeholder="Description"
                  editor={ClassicEditor}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleChangeText(data);
                    console.log({ event, editor, data });
                  }}
                  // data={
                  //   project.project_description
                  //     ? parse(project.project_description)
                  //     : "Enter project description"
                  // }
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
              <p className="err-message">{formErrors.project_description}</p>
              <div className="form-group">
                <label>Announcement Category</label>
                <select
                  className="selectdropdownlist"
                  name="solution_name"
                  value={formValues.solution_name}
                  onChange={handleChange}
                  {...register("solution_name", {
                    required: "Please select a solution name",
                    onChange: handleChange,
                  })}
                >
                  <option>Select Solutions</option>
                  {solutions.map((solutions) => (
                    <option key={solutions.id} value={solutions.solutions_name}>
                      {solutions.solutions_name}
                    </option>
                  ))}
                </select>
              </div>
              {errors.solution_name && (
                <p className="err-message">{errors.solution_name.message}</p>
              )}

              <div className="fieldsgroup">
                <div className="formfieldsinput">
                  <div className="form-group">
                    <label>Country</label>
                    <Select
                      name="country"
                      options={countries}
                      onChange={handleCountryChange}
                      value={selectedCountry}
                      placeholder="Select Country"
                      className="custom-select-control"
                      styles={{
                        indicatorsContainer: (provided, state) => ({
                          ...provided,
                          display: "none",
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          height: "40px",
                          backgroundColor: "#f8f8f8",
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          marginLeft: "5px",
                          marginBottom: "5px",
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          marginBottom: "8px",
                        }),
                        input: (provided, state) => ({
                          ...provided,
                          margin: "0 px",
                          paddingBottom: "0px",
                          paddingTop: "0px",
                          marginTop: "-5px",
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="formfieldsinput">
                  <div className="form-group">
                    <label>State</label>
                    <Select
                      name="state"
                      options={statesOptions}
                      onChange={handleStateChange}
                      value={selectedState}
                      placeholder="Select State"
                      className="custom-select-control"
                      styles={{
                        indicatorsContainer: (provided, state) => ({
                          ...provided,
                          display: "none",
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          height: "40px",
                          backgroundColor: "#f8f8f8",
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          marginLeft: "5px",
                          marginBottom: "5px",
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          marginBottom: "8px",
                        }),
                        input: (provided, state) => ({
                          ...provided,
                          margin: "0 px",
                          paddingBottom: "0px",
                          paddingTop: "0px",
                          marginTop: "-5px",
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="formfieldsinput">
                  <div className="form-group">
                    <label>City</label>
                    <Select
                      name="city"
                      options={citiesOptions}
                      onChange={handleCityChange}
                      value={selectedCity}
                      placeholder="Select city"
                      className="custom-select-control"
                      styles={{
                        indicatorsContainer: (provided, state) => ({
                          ...provided,
                          display: "none",
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          height: "40px",
                          backgroundColor: "#f8f8f8",
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          marginLeft: "5px",
                          marginBottom: "5px",
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          marginBottom: "8px",
                        }),
                        input: (provided, state) => ({
                          ...provided,
                          margin: "0 px",
                          paddingBottom: "0px",
                          paddingTop: "0px",
                          marginTop: "-5px",
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="fieldsgroup">
                <div className="formfieldsinput">
                  <div className="form-group">
                    <label>Announcement Expiry Date</label>

                    <DatePicker
                      className="customer-project-input"
                      selected={startDate}
                      onChange={handleDateChange}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="time"
                      dateFormat="dd-MM-yyyy hh:mm aa"
                      name="project_expiry_datetime"
                      // value={formValues.project_expiry_datetime}
                    />
                  </div>
                  <p className="err-message">
                    {formErrors.project_expiry_datetime}
                  </p>
                </div>
                <div className="formfieldsinput">
                  <div className="form-group">
                    <label>
                      Project Budget in $USD{" "}
                      <span className="formnote">
                        (Enter Your Project Budget here)
                      </span>
                    </label>
                    <input
                      className="customer-project-input"
                      type="text"
                      name="project_budget"
                      value={formValues.project_budget}
                      placeholder="Enter your project budget here"
                      onChange={handleChange}
                    />
                  </div>
                  <p className="err-message">{formErrors.budget}</p>
                </div>
              </div>
              <div className="form-group">
                <label>
                  Attachment
                  <span className="formnote">(Attach PDF files Only)</span>
                </label>
                <input
                  type="file"
                  // value={selectedFile}
                  onChange={handleFileInput}
                  accept="application/pdf"
                />
              </div>
              {selectedFileError && (
                <span className="error-msg">{selectedFileError}</span>
              )}
              <br />
              <div className="form-group submit-btn-customer">
                <button
                  type="submit"
                  className="btn btn-primary project-submit"
                >
                  <i className="far fa-paper-plane"></i>Submit Project
                </button>
              </div>
            </form>
          </main>

          <Modal isOpen={isModal} contentLabel="Confirmation Modal">
            {" "}
            <h2>testing</h2> <button>Cancel</button> <button>Confirm</button>{" "}
          </Modal>
        </div>
      </div>
    </>
  );
};

export default AddProject;
